import React, { Component } from 'react'
import styles from 'src/styles/items.module.css'
import Project from 'src/components/Projects/Project'
import Title from 'src/components/StyledTitle'
import ReactWordcloud from 'react-wordcloud';
import { Resizable } from 're-resizable';
import words from 'src/constants/keywords';

export default class ProjectList extends Component {
    constructor(props) {
        super(props);
        this.state={
            projects : [],
            sortedProjects: []
        }

        this.resizeStyle = {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        }
        this.options = {
            enableTooltip: true,
            fontFamily: 'impact',
            fontSizes: [5, 60],
            fontStyle: 'normal',
            fontWeight: 'normal',
            padding: 1,
            scale: 'sqrt',
            spiral: 'archimedean',
        }
      }

      callbacks = {
        getWordTooltip: word => `Filter by "${word.text}"`,
        onWordClick: (word, event) => {
            this.setState((prevState) => ({sortedProjects: prevState.projects.filter(proj => proj.node.frontmatter.tags.includes(word.text))} ))
        },
    }

    componentDidMount(){
        this.setState({
            projects: this.props.projects.edges,
            sortedProjects: this.props.projects.edges,
        })
    }
    render() {
        return (
            <div>
            <Resizable style={this.resizeStyle}>
            <div style={{ width: '100%', height: '100%' }}>
              <ReactWordcloud words={words} options={this.options} callbacks={this.callbacks} />
            </div>
          </Resizable>
            <section className={styles.projects}>
                <Title title="personal" subtitle="projects" />
                <div className={styles.center}>
                    {
                        this.state.sortedProjects.map(({ node }) => {
                            return <Project key={node.id} project={node} />
                        })
                    }
                </div>
            </section>
            </div>
        )
    }
}