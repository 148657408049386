import React from 'react'
import ProjectList from 'src/components/Projects/ProjectList'
import { useStaticQuery, graphql } from 'gatsby'

const Projects = () => {
    const { projects } = useStaticQuery(getProjects)

    return (
        <ProjectList projects={projects} />      
    )
}

const getProjects = graphql`
query {
  projects:allMarkdownRemark(filter: {frontmatter: {type: {eq:"project"}}}) {
    edges {
      node {
        html
        id
        fields {
          slug
        }
        frontmatter {
          title
          description
          tags
          github
          mainImage {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      }
    }
  }
}
`

export default Projects