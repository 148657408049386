import React from 'react'
import Projects from 'src/components/Projects/Projects'
import Layout from 'src/components/layout'
import SEO from "src/components/seo"

const portfolio = () => {
    return (
        <Layout>
          <SEO title="Portfolio" />
          <Projects />
        </Layout>
    )
}

export default portfolio
