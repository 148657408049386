export default [
    {
      text: 'C#',
      value: 80,
    },
    {
      text: 'Python',
      value: 60,
    },
    {
      text: 'JavaScript',
      value: 50,
    },
    {
      text: 'Swift',
      value: 30,
    },
    {
      text: 'Java',
      value: 40,
    },
    {
      text: 'HTML',
      value: 54,
    },
    {
      text: 'CSS',
      value: 30,
    },
    {
      text: 'gRPC',
      value: 30,
    },
    {
      text: 'ProtoBuf',
      value: 30,
    },
    {
      text: 'JSON',
      value: 19,
    },
    {
      text: 'SQL',
      value: 60,
    },
    {
      text: 'AWS',
      value: 65,
    },
    {
      text: 'Azure',
      value: 10,
    },
    {
      text: 'DevOps',
      value: 35,
    },
    {
      text: 'NUnit',
      value: 30,
    },
    {
      text: 'Roslyn',
      value: 38,
    },
    {
        text: 'React',
        value: 45,
    },
    {
        text: 'Gatsby',
        value: 30,
    },
    {
        text: 'GraphQL',
        value: 38,
    },
    {
        text: '.Net',
        value: 70,
    },
    {
        text: 'Machine Learning',
        value: 20,
    },
    {
        text: 'Computer Vision',
        value: 20,
    },
    {
        text: 'TensorFlow',
        value: 18,
    },
    {
        text: 'Distributed Systems',
        value: 50,
    },
    {
        text: 'Rest API',
        value: 45,
    },
    {
        text: 'MVC',
        value: 65,
    },
    {
        text: 'Node.JS',
        value: 32,
    },
    {
        text: 'IOS',
        value: 20,
    },
    {
        text: 'BASH',
        value: 15,
    },
    {
        text: 'Jupyter',
        value: 20,
    },
    {
        text: 'DynamoDB',
        value: 28,
    },
    {
        text: 'SQLite',
        value: 28,
    },
        
  ];
  